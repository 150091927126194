import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import Allrecipes from "../components/Allrecipes"
import SEO from "../components/SEO"


export default function Home() {

  return <Layout>
  <SEO title= "Home" />
    <main className="page">
      <header className="hero">
        <StaticImage
          src="../assets/images/main.jpg"
          alt="Hilsha" className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
        <div className="hero-container">
          <div className="hero-text">
            <h1>Bengali Plates</h1>
            <h4>The Taste of Bengal</h4>
          </div>
          
        </div>
       </header>
       
      
      <Allrecipes/>
    </main>
    
  
  </Layout>
}
