import React from 'react'
import Taglist from './Taglist'
import Recipeslist from './Recipeslist'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
query {
  allContentfulBengaliPlate {
    nodes {
      id
      title
      cookTime
      prepTime
      content {
        tags
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
}
`

const Allrecipes = () => {
  const data = useStaticQuery(query);
  const recipes = data.allContentfulBengaliPlate.nodes
  
  return (
    <section className='recipes-container'>
      <Taglist recipes={recipes}/>
      <div><h3>Bengali Special Food Recipes</h3>
      <Recipeslist recipes={recipes}/>
      </div>
     
      
    </section>
  )
}

export default Allrecipes
